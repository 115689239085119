import react, { useState } from "react";

const debounce = (fn, timeout = 750) => {
  let timer;

  return () => {
    clearTimeout(timer);

    timer = setTimeout(() => fn(), timeout);
  };
};

const useInput = (validate) => {
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const checkValidity = (value) => {
    if (validate(value)) {
      setError(false);
      setIsValid(true);
    } else {
      setError(true);
      setIsValid(false);
    }
  };

  const handleChange = (e) => {
    const enteredValue = e.currentTarget.value;

    setInput(enteredValue);
    debounce(() => checkValidity(enteredValue))();
  };

  const handleBlur = () => {
    checkValidity(input);
  };

  const reset = () => {
    {
      setInput("");
      setError(false);
      setIsValid(false);
    }
  };

  return {
    input,
    error,
    isValid,
    reset,
    onChange: handleChange,
    onBlur: handleBlur,
  };
};

export default useInput;
