import * as React from "react";
import Head from "../Head/Head";
import NavBar from "../sections/NavBar/NavBar";
import HeroSection from "../sections/HeroSection/HeroSection";
import InfoSection from "../sections/InfoSection/InfoSection";
import ImageSection from "../sections/ImageSection/ImageSection";
import LocationMapSection from "../sections/LocationMapSection/LocationMapSection";
import NewsSection from "../sections/NewsSection/NewsSection";
import ContactSection from "../sections/ContactSection/ContactSection";
import Footer from "../sections/Footer/Footer";
import PizzaGuy from "../PizzaGuy/PizzaGuy";
import CookieBanner from "../sections/CookieBanner/CookieBanner";
// import MessageBanner from "../sections/MessageBanner/MessageBanner";

export default function Layout() {

  const [isMobile, setMobile] = React.useState(false);
  const [cookiesAllowed, setCookiesAllowed] = React.useState(false);
  const [isBannerOpen, setBannerOpen] = React.useState(false);

  const mediaQueryHandler = (query) => {
    setMobile(!query.matches);
  }

  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 550px)');
    mediaQueryHandler(mediaQuery);
    mediaQuery.addEventListener('change', mediaQueryHandler);

    return () => {
      mediaQuery.removeEventListener('change', mediaQueryHandler);
    }
  }, []);

  return (
    <>
      <Head />
      <NavBar />
      <HeroSection />
      {
        isMobile ?
          <>
            <NewsSection />
            <ImageSection />
            <InfoSection isMobile={isMobile} />
            <LocationMapSection isMobile={isMobile} cookiesAllowed={cookiesAllowed} setCookiesAllowed={setCookiesAllowed} />
          </>
          :
          <>
            <InfoSection isMobile={isMobile} />
            <ImageSection />
            <LocationMapSection isMobile={isMobile} cookiesAllowed={cookiesAllowed} setCookiesAllowed={setCookiesAllowed} />
            <NewsSection />
          </>
      }
      <ContactSection />
      <Footer />
      <PizzaGuy isBannerOpen={isBannerOpen} />
      <CookieBanner cookiesAllowed={cookiesAllowed} setCookiesAllowed={setCookiesAllowed} showBanner={isBannerOpen} setBanner={setBannerOpen} />
      {/* <MessageBanner /> */}
    </>
  );
}
