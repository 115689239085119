import * as React from 'react'
import * as styles from './styles.module.css'

import { apiUrl } from '../../app.config';

import PizzaGame from '../PizzaGame/PizzaGame';
import clsx from 'clsx';

import pizzaguyimage from '../../images/pizza-guy.webp'

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  setCookieWithDate(cname, cvalue, d);
}

function setCookieWithDate(cname, cvalue, expdate) {
  const expires = "expires=" + expdate.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function removeAllCookies(exceptions = []) {
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (!exceptions.includes(name.trim()))
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export default function PizzaGuy({ isBannerOpen }) {

  const [showPizzaGuy, setPizzaGuy] = React.useState(false);
  const [showBubble, setBubble] = React.useState(false);
  const [countDown, setCountDown] = React.useState(null);
  const [reload, setReload] = React.useState(0);
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [isGameOpen, setisGameOpen] = React.useState(false);

  const reloadPizzyGuy = () => {
    setReload((old) => {
      return old += 1;
    })
  }

  const padNumber = (number) => {
    let str = '00' + number;
    return str.substring(str.length - 2);
  }

  const calculateCountDown = (targetDate) => {

    const target = targetDate.getTime();
    const now = new Date().getTime();

    const distance = target - now;

    if (distance < 0 || !target) {
      reloadPizzyGuy();
      return;
    }

    const days = padNumber(Math.floor(distance / (1000 * 60 * 60 * 24)));
    const hours = padNumber(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const minutes = padNumber(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = padNumber(Math.floor((distance % (1000 * 60)) / 1000));

    setCountDown(`${days}:${hours}:${minutes}:${seconds}`);

  }

  const GAME_VERSION = '1';

  const doVersionCheck = () => {
    const currentVersion = getCookie('gameversion');
    const exceptions = ['cookiebanner', 'gameversion'];
    if (!currentVersion || currentVersion !== GAME_VERSION) {
      removeAllCookies(exceptions);
      setCookie('gameversion', GAME_VERSION, 14);
    }
  }

  React.useEffect(() => {

    if (isBannerOpen || !document.cookie.includes('cookiebanner'))
      return () => { };

    doVersionCheck();

    let timeout, timeout2;
    let interval;

    if (document.cookie.includes('pizzaguytimeout')) {
      const timeoutdate = new Date(getCookie('pizzaguytimeout'));
      if (timeoutdate > new Date()) {
        interval = setInterval(() => {
          calculateCountDown(timeoutdate);
        }, 1000);
        timeout = setTimeout(() => {
          setPizzaGuy(true);
          setIsMinimized(true);
        }, 500);
        return () => {
          clearTimeout(timeout);
          clearInterval(interval);
        };
      }
    }

    fetch(`${apiUrl}/minigame/available`, {
      method: 'get',
    }).then((res) => {

      if (res.status !== 200) {
        setPizzaGuy(false);
        return;
      }

      res.json().then((res) => {

        if (res.tryagain === 'unknown') {
          setPizzaGuy(false);
          return;
        }

        if (!res.available) {
          const cookieexpdate = new Date();
          cookieexpdate.setHours(cookieexpdate.getHours() + 12);
          setCookieWithDate('pizzaguytimeout', new Date(res.tryagain).toISOString(), cookieexpdate);
          interval = setInterval(() => {
            calculateCountDown(new Date(res.tryagain));
          }, 1000);
        } else {
          setCountDown(null);
          setCookie('pizzaguytries', res.tries - 1, 1);
        }

        timeout = setTimeout(() => {
          setPizzaGuy(true);
          timeout2 = setTimeout(() => {
            setBubble(true);
          }, 1200);
        }, 500);

      });

    });

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
      clearInterval(interval);
    }

  }, [isBannerOpen, reload])

  const hidePizzaGuy = () => {
    setIsMinimized(true);
    setBubble(false);
    reloadPizzyGuy();
  }

  const onClickHandler = () => {
    if (!isMinimized) {
      onBubbleClickHandler();
    } else {
      setIsMinimized(false);
      setBubble(true);
    }
  }

  const onBackgroundClickHandler = () => {
    setIsMinimized(true);
  }

  const onBubbleClickHandler = () => {
    if (!countDown) {
      setIsMinimized(true);
      setisGameOpen(true);
      document.body.style.overflow = 'hidden';
    }
  }

  const onGameExitClickHandler = () => {
    setisGameOpen(false)
    hidePizzaGuy(0);
    document.body.style.overflow = '';
  }

  return (
    <>
      {
        showPizzaGuy &&
        <div className={clsx(styles.container, isMinimized ? styles.minimized : '')} onClick={onClickHandler}>
          <img className={styles.pizzaguy} src={pizzaguyimage}></img>
        </div>
      }
      {
        showPizzaGuy && showBubble && !isMinimized &&
        <div className={clsx(styles.bubble, styles.bubblepos)} onClick={onBubbleClickHandler}>
          {
            !countDown &&
            <>Klicke hier, um eine <strong>gratis</strong> Pizza zu gewinnen!</>
          }
          {
            countDown &&
            <>Du kannst erneut um eine gratis Pizza spielen in:<br /><strong>{countDown}</strong></>
          }
        </div>
      }
      {
        showPizzaGuy && showBubble && !isMinimized &&
        <div className={styles.overlay} onClick={onBackgroundClickHandler}></div>
      }
      {
        isGameOpen &&
        <div className={styles.gameoverlay}>
          <div className={styles.gamebubble}>
            <div className={styles.exitbutton}>
              <div className={styles.exitclick} onClick={onGameExitClickHandler}>
                <div className={clsx(styles.exitall, styles.exit1)}></div>
                <div className={clsx(styles.exitall, styles.exit2)}></div>
              </div>
            </div>
            <div className={styles.gamediv}>
              <PizzaGame hideFunction={hidePizzaGuy}></PizzaGame>
            </div>
          </div>
        </div>
      }
    </>
  )
}
