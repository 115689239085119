import * as React from 'react'
import * as styles from './styles.module.css'

import { apiUrl } from '../../app.config';

import pizzaImage from '../../images/pizza.png';
import ovenImage from '../../images/oven.png';
import ovenOverlayImage from '../../images/oven-overlay.png';

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function randomIntBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default function PizzaGame(props) {

  const [hasGameStarted, setGameStarted] = React.useState(false);
  const [overlayText, setOverlayText] = React.useState('');
  const [retries, setRetries] = React.useState(getCookie('pizzaguytries') ? Number(getCookie('pizzaguytries')) : 2);
  const [gameOver, setGameOver] = React.useState(false);
  const [winningScreen, setWinningScreen] = React.useState(false);
  const [emailScreen, setEmailScreen] = React.useState(false);
  const [loadingScreen, setLoadingScreen] = React.useState(false);
  const [thankyouScreen, setThankyouScreen] = React.useState(false);
  const [maxWinsScreen, setMaxWinsScreen] = React.useState(false);
  const [emailNotValidScreen, setEmailNotValidScreen] = React.useState(false);
  const [errorScreen, setErrorScreen] = React.useState(false);

  const pizzaimage = React.useRef(null);
  const ovenimage = React.useRef(null);
  const ovenoverlayimage = React.useRef(null);

  function drawArrow(canvasContext, arrow, ptArrow, angleInDegrees) {
    canvasContext.save();

    canvasContext.translate(ptArrow.x, ptArrow.y);
    canvasContext.rotate(angleInDegrees * Math.PI / 180);

    canvasContext.beginPath();
    canvasContext.moveTo(0, 0);
    canvasContext.lineTo(0, -arrow.h);
    canvasContext.lineTo(arrow.w, 0);
    canvasContext.lineTo(0, +arrow.h);
    canvasContext.closePath();
    canvasContext.fillStyle = "rgb(72,72,72)";
    // canvasContext.stroke();
    canvasContext.fill();

    canvasContext.restore();
  }

  function drawCircle(canvasContext, circle) {
    canvasContext.beginPath();
    canvasContext.fillStyle = "rgb(43,166,203)";
    canvasContext.lineWidth = 0;
    canvasContext.arc(circle.x, circle.y, circle.r, 0, 2 * Math.PI, false);
    canvasContext.fill();
  }

  function drawLine(canvasContext, startPt, endPt) {
    canvasContext.moveTo(startPt.x, startPt.y);
    canvasContext.lineTo(endPt.x, endPt.y);
    canvasContext.lineWidth = 5;
    canvasContext.strokeStyle = "rgb(72,72,72)";
    canvasContext.stroke();
    canvasContext.lineWidth = 1;
  }

  let degree = -90;
  let toggle = false;
  let shoot = false;
  let distance = 0;
  let isOut = false;
  let rotating = 0;

  let isHTTPCallDone = false;

  let angleSpeedArgument;

  const gameTick = () => {

    const canvas = document.getElementById('gamecanvas');
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const ratioHeight = 497;
    const ratioWidth = 345;

    const imageScale = 0.35;

    const boxHeight = 320 * imageScale * canvas.height / ratioHeight;
    const boxWidth = 512 * imageScale * canvas.width / ratioWidth;

    // ctx.fillRect(canvas.width / 2 - boxWidth / 2, 0, boxWidth, boxHeight);
    ctx.drawImage(ovenimage.current, canvas.width / 2 - boxWidth / 2, 0, boxWidth, boxHeight);

    var pizzaCircle = {
      x: canvas.width / 2,
      y: canvas.height - canvas.height / 8,
      r: 10
    };

    const arrowLength = 75 * canvas.width / ratioWidth;

    const targetPoint = {
      x: pizzaCircle.x + Math.cos(degree * Math.PI / 180) * arrowLength,
      y: pizzaCircle.y + Math.sin(degree * Math.PI / 180) * arrowLength
    }

    drawLine(ctx, pizzaCircle, targetPoint);
    drawArrow(ctx, { h: 5 * 2, w: 10 * 2 }, targetPoint, degree);

    const speed = 11 * canvas.width / ratioWidth;

    if (shoot && !isOut) {
      pizzaCircle.x += Math.cos(degree * Math.PI / 180) * distance;
      pizzaCircle.y += Math.sin(degree * Math.PI / 180) * distance;
      distance = distance + speed;
    }

    const imageRadius = 28 * canvas.width / ratioWidth;

    if (shoot) {
      ctx.translate(pizzaCircle.x, pizzaCircle.y);
      ctx.rotate((rotating += 10) * Math.PI / 180);
      ctx.translate(-pizzaCircle.x, -pizzaCircle.y);
    }

    drawCircle(ctx, pizzaCircle);
    ctx.drawImage(pizzaimage.current, pizzaCircle.x - imageRadius, pizzaCircle.y - imageRadius, imageRadius * 2, imageRadius * 2);

    shoot && ctx.setTransform(1, 0, 0, 1, 0, 0);

    const angleSpeed = angleSpeedArgument;
    if (!shoot && overlayText === '') {
      degree = degree + (toggle ? +angleSpeed : -angleSpeed);
      if (degree < -180 || degree > 0)
        toggle = !toggle;
    }

    const middleAngle = -90;
    const winSpread = 5;
    const isWinning = middleAngle - winSpread <= degree && middleAngle + winSpread >= degree;

    if (isWinning)
      ctx.drawImage(ovenoverlayimage.current, canvas.width / 2 - boxWidth / 2, 0, boxWidth, boxHeight);

    if (shoot && !isWinning && !isHTTPCallDone) {
      isHTTPCallDone = true;
      fetch(`${apiUrl}/minigame/try`);
    }

    const outzone = isWinning ? 550 : 250;
    isOut = pizzaCircle.x < 0 - outzone || pizzaCircle.x > canvas.width + outzone || pizzaCircle.y < 0 - outzone || pizzaCircle.y > canvas.height + outzone;
    if (isOut && !isWinning) {
      setOverlayText(`Noch ${retries} ${retries === 1 ? 'Versuch' : 'Versuche'}`);
      setRetries(retries - 1);
      if (retries <= 0) {
        setGameStarted(false);
        setGameOver(true);
        props.hideFunction();
      }
    } else if (isOut && isWinning) {
      setGameStarted(false);
      setWinningScreen(true);
    }

  }

  const resizeEvent = () => {
    if (hasGameStarted) {
      const containerWidth = document.getElementById('gamecontainer').clientWidth;
      const containerHeight = document.getElementById('gamecontainer').clientHeight;

      let newHeight = containerWidth * 497 / 345;

      let newRatio = 1;
      if (newHeight > containerHeight) {
        newRatio = containerHeight / newHeight;
      }

      document.getElementById('gamecanvas').width = containerWidth * newRatio * 2;
      document.getElementById('gamecanvas').height = newHeight * newRatio * 2;
    }
  }

  const startClickHandler = () => {
    setGameStarted(true);
    setOverlayText('Klicke um zu schießen!');
  }

  const overlayClickHandler = () => {
    setOverlayText('');
  }

  const winningScreenClickHandler = () => {
    setEmailScreen(true);
    setWinningScreen(false);
  }

  const emailNotValidScreenClickHandler = () => {
    winningScreenClickHandler();
    setEmailNotValidScreen(false);
  }

  const clickCanvasHandler = () => {
    shoot = true;
  }

  const emailSubmitHandler = (event) => {
    event.preventDefault();
    setLoadingScreen(true);

    fetch(`${apiUrl}/minigame/play`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'email': event.target.email.value
      })
    }).then((res) => {
      const status = res.status;
      res.text().then((res) => {
        setLoadingScreen(false);
        if (status === 200 && res === 'ok') {
          setThankyouScreen(true);
          props.hideFunction();
        } else if (status === 400 && res === 'max wins reached') {
          setMaxWinsScreen(true);
          props.hideFunction();
        } else if (status === 400 && res === 'email not valid') {
          setEmailScreen(false);
          setEmailNotValidScreen(true);
        } else {
          setErrorScreen(true);
        }
      });
    });
  }

  React.useEffect(() => {

    if (!pizzaimage.current) {
      pizzaimage.current = new Image();
      pizzaimage.current.src = pizzaImage;
    }

    if (!ovenimage.current) {
      ovenimage.current = new Image();
      ovenimage.current.src = ovenImage;
    }

    if (!ovenoverlayimage.current) {
      ovenoverlayimage.current = new Image();
      ovenoverlayimage.current.src = ovenOverlayImage;
    }

    if (!hasGameStarted)
      return;

    resizeEvent();
    window.addEventListener('resize', resizeEvent, false)

    angleSpeedArgument = randomIntBetween(6, 8);

    const intv = setInterval(gameTick, 1000 / 60);

    return () => {
      clearInterval(intv);
      window.removeEventListener('resize', resizeEvent);
    }

  }, [hasGameStarted, overlayText, retries]);

  React.useEffect(() => {
    setCookie('pizzaguytries', retries, 1);
  }, [retries])

  return (
    <>
      {
        hasGameStarted &&
        <div id='gamecontainer' className={styles.gamecontainer} onClick={clickCanvasHandler}>
          <canvas id='gamecanvas' className={styles.gamecanvas}></canvas>
        </div>
      }
      {
        !hasGameStarted && !gameOver && !winningScreen && !emailScreen && !emailNotValidScreen &&
        <div className={styles.startgametext} onClick={startClickHandler}>
          <h1>Jetzt Starten!</h1>
          <h3>{`Du hast ${retries + 1} ${retries === 0 ? 'Versuch' : 'Versuche'}`}</h3>
        </div>
      }
      {
        hasGameStarted && (overlayText !== '') &&
        <div className={styles.canvasoverlay} onClick={overlayClickHandler}>
          <h3>{overlayText}</h3>
        </div>
      }
      {
        !hasGameStarted && gameOver &&
        <div className={styles.startgametext}>
          <h1>Leider nicht gewonnen</h1>
          <h3>Versuche es morgen erneut</h3>
        </div>
      }
      {
        !hasGameStarted && winningScreen &&
        <div className={styles.startgametext} onClick={winningScreenClickHandler}>
          <h1 style={{ margin: 0 }}>Glückwunsch!</h1>
          <h3 style={{ color: 'black' }}>Sie haben einen Gutschein für eine gratis Pizza gewonnen!</h3>
          <h3>Klicken um fortzufahren</h3>
        </div>
      }
      {
        !hasGameStarted && emailScreen &&
        <div className={styles.winninggametext}>
          {
            !loadingScreen && !thankyouScreen && !maxWinsScreen && !errorScreen &&
            <>
              <h1 style={{ margin: '0' }}>Gewonnen!</h1>
              <h3>Geben Sie ihre E-Mail Adresse ein,<br />um ihren Gutschein zu erhalten!</h3>
              <form className={styles.formcontainer} onSubmit={emailSubmitHandler}>
                <div className={styles.textboxcontainer}>
                  <input id='email' name='email' type='email' className={styles.textbox} required />
                  <p className={styles.privacytext}>Wenn Sie fortfahren, erklären Sie sich mit unserer <a href='/datenschutz' target='_blank'>Datenschutzerklärung</a> einverstanden.</p>
                </div>
                <input type="submit" className={styles.button} value="Abschicken" />
              </form>
            </>
          }
          {
            loadingScreen &&
            <div className={styles.loader}></div>
          }
          {
            thankyouScreen &&
            <>
              <h1>Vielen Dank</h1>
              <h3>Sie erhalten ihren Gutschein in Kürze per E-Mail</h3>
            </>
          }
          {
            maxWinsScreen &&
            <>
              <h1>Tut uns leid</h1>
              <h3>Sie haben diese Woche bereits einen Gutschein erhalten. Versuchen Sie es in einer Woche erneut!</h3>
            </>
          }
          {
            errorScreen &&
            <>
              <h1>Tut uns leid</h1>
              <h3>Ein Fehler, ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!</h3>
            </>
          }
        </div>
      }
      {
        !hasGameStarted && emailNotValidScreen &&
        <div className={styles.startgametext} onClick={emailNotValidScreenClickHandler}>
          <h1 style={{ margin: 0 }}>Fehler</h1>
          <h3 style={{ color: 'black' }}>Die eingegebene E-Mail ist leider nicht gültig!</h3>
          <h3>Klicken um fortzufahren</h3>
        </div>
      }
    </>
  )
}
