// extracted by mini-css-extract-plugin
export var centercontainer = "styles-module--centercontainer--+7lsU";
export var container = "styles-module--container--7AVxw";
export var feature = "styles-module--feature--lXSvz";
export var features = "styles-module--features---Vm8b";
export var heading = "styles-module--heading--kmSF6";
export var headingtext = "styles-module--headingtext--wvF1N";
export var icon = "styles-module--icon--xFjuD";
export var section = "styles-module--section--UPIhq";
export var title = "styles-module--title--zPFBH";
export var underline = "styles-module--underline--MI6IX";