// extracted by mini-css-extract-plugin
export var blockcontainer = "styles-module--blockcontainer--zw6Ga";
export var callToActions = "styles-module--callToActions--Z30lk";
export var centercontainer = "styles-module--centercontainer--ER-10";
export var contentContainer = "styles-module--contentContainer--NB25Q";
export var heading = "styles-module--heading--RM+o+";
export var imagecontainer = "styles-module--imagecontainer--6P5fz";
export var leftside = "styles-module--leftside--HbZl+";
export var locationLink = "styles-module--locationLink--zfDGQ";
export var placeholder = "styles-module--placeholder--zhpmR";
export var rightside = "styles-module--rightside--m2eUS";
export var section = "styles-module--section--b2s9D";
export var timebox = "styles-module--timebox--W7rVL";
export var topHeading = "styles-module--topHeading--Ixa50";