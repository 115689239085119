import * as React from "react";
import { apiUrl } from "../../../app.config";
import useInput from "../../../hooks/useInput";
import ButtonHover from "../../ui/ButtonHover/ButtonHover";

import * as styles from "./styles.module.css";

export default function ContactSection(props) {
  const nameInput = useInput((value) => true);
  const emailRegEx = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
  const emailInput = useInput((value) => value.trim().length === 0 || emailRegEx.test(value));
  const messageInput = useInput((value) => value.trim().length > 0);

  const isValid =
    (emailInput.input.trim().length === 0 || emailInput.isValid) && messageInput.isValid;

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const sendMail = () => {
    setLoading(true);

    fetch(`${apiUrl}/contactform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: nameInput.input,
        email: emailInput.input,
        message: messageInput.input,
      }),
    })
      .then((response) => {
        setLoading(false);

        if (response.ok) {
          setSuccess(true);
        } else {
          setError(true);
        }

        setTimeout(() => {
          resetState();
        }, 3000);
      })
      .catch((error) => {
        setError(true);

        setTimeout(() => {
          resetState();
        }, 3000);
      });
  };

  const resetState = () => {
    nameInput.reset();
    emailInput.reset();
    messageInput.reset();

    setSuccess(false);
    setLoading(false);
    setError(false);
  };

  return (
    <>
      <div id='kontakt' className={styles.fakeanchor}></div>
      <div className={styles.container}>
        <h2 className={styles.heading}>Haben Sie uns etwas zu sagen?</h2>
        <p className={styles.paragraph}>
          Haben Sie Ideen, Anregungen oder Wünsche? Lassen Sie es uns wissen. Wir freuen uns über ihr Feedback.
        </p>
        <form className={styles.formContainer}>
          <label htmlFor='name'>
            Name <span className={styles.optional}>(optional)</span>
          </label>
          <input
            value={nameInput.input}
            onChange={nameInput.onChange}
            onBlur={nameInput.onBlur}
            type='text'
            id='name'
            name='name'
          />
          <br />
          <label htmlFor='email'>
            Email <span className={styles.optional}>(optional)</span>
          </label>
          <input
            value={emailInput.input}
            onChange={emailInput.onChange}
            onBlur={emailInput.onBlur}
            type='text'
            id='email'
            name='email'
          />
          <span className={`${styles.error} ${emailInput.error && styles.show}`}>
            Bitte geben Sie eine gültige Email Adresse ein
          </span>
          <label htmlFor='message'>Nachricht *</label>
          <textarea
            value={messageInput.input}
            onChange={messageInput.onChange}
            onBlur={messageInput.onBlur}
            name='message'
            placeholder='Ihre Nachricht'
            style={{ resize: "none" }}></textarea>
          <span className={`${styles.error} ${messageInput.error && styles.show}`}>
            Bitte geben Sie eine Nachricht ein
          </span>
          {success && (
            <Overlay>
              Dankeschön!
              <br />
              Ihre Nachricht wurde an uns weitergeleitet.
            </Overlay>
          )}
          {loading && (
            <Overlay>
              <Loader />
            </Overlay>
          )}
          {error && (
            <Overlay>
              Senden fehlgeschlagen.
              <br />
              Bitte versuchen Sie es später erneut.
            </Overlay>
          )}
        </form>
        <div
          onClick={() => sendMail()}
          className={`${styles.buttonContainer} ${!isValid && styles.disabled}`}>
          <ButtonHover size='LARGE'>Senden</ButtonHover>
        </div>
      </div>
    </>
  );
}

const Overlay = (props) => {
  return <div className={styles.overlay}>{props.children}</div>;
};

const Loader = () => {
  return <div className={styles.loader}></div>;
};
