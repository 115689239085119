// extracted by mini-css-extract-plugin
export var bigimage = "styles-module--bigimage--4HF7T";
export var box = "styles-module--box--bAOli";
export var boxbottom = "styles-module--boxbottom--OSKjg";
export var boxtop = "styles-module--boxtop--h-C4k";
export var container = "styles-module--container--KUf9V";
export var halfcontainer = "styles-module--halfcontainer--ZFHb2";
export var newsimage = "styles-module--newsimage--v5NES";
export var onimagebox = "styles-module--onimagebox--SydKy";
export var onimagetext = "styles-module--onimagetext--2KaPS";
export var overlay = "styles-module--overlay--liOnR";