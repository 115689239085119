import * as React from "react"
import Layout from "../components/Layout/Layout"
import './global.css'


const IndexPage = () => {
    return (
        <Layout />
    )
}

export default IndexPage
