import clsx from 'clsx';
import * as React from 'react'
import * as styles from './styles.module.css'

export default function NewsPart(props) {

  const [bigImage, showImage] = React.useState(null);

  const visible = props.visible;
  props = props.props;

  React.useEffect(() => {
    document.body.style.overflow = bigImage ? 'hidden' : '';
  }, [bigImage])

  return (
    <>
      <div style={{ display: visible ? '' : 'none' }}>
        <div className={styles.container}>
          <div className={styles.halfcontainer} onClick={() => showImage(props.image)}>
            <img className={styles.newsimage} src={props.image} alt="News Image" />
            {
              props.type !== 'nodescription' &&
              <div className={clsx(styles.onimagebox, props.type === 'top' ? styles.boxtop : styles.boxbottom)}>
                <h2 className={styles.onimagetext}>{props.title}</h2>
                <p className={styles.onimagetext}>{props.description}</p>
              </div>
            }
          </div>
        </div>
      </div>
      {
        bigImage &&
        <div className={styles.overlay} onClick={() => showImage(null)}>
          <div className={styles.box}>
            <img className={styles.bigimage} src={bigImage} alt="News Image" />
          </div>
        </div>
      }
    </>
  )
}
