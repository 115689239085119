// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--AgDIu";
export var arrowcontainer = "styles-module--arrowcontainer--KKKlf";
export var circle = "styles-module--circle--hTwF9";
export var circlecontainer = "styles-module--circlecontainer--JY1Hs";
export var circlehighlight = "styles-module--circlehighlight--S5A1l";
export var container = "styles-module--container--35Qlt";
export var controlcontainer = "styles-module--controlcontainer--NrZDU";
export var heading = "styles-module--heading--jHbK7";
export var leftarrow = "styles-module--leftarrow--PDnBB";
export var newscontainer = "styles-module--newscontainer--Jtcii";
export var rightarrow = "styles-module--rightarrow--Y+l57";
export var section = "styles-module--section---vlBo";
export var underline = "styles-module--underline--FDZBf";