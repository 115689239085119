import { useEffect } from "react"

const TouchMovement = (element, callb) => {

    let xDown = null;
    let yDown = null;

    const handleTouchStart = (evt) => {
        const firstTouch = evt.touches[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt) => {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                callb('right');
            } else {
                callb('left');
            }
        } else {
            if (yDiff > 0) {
                callb('down');
            } else {
                callb('up');
            }
        }
        xDown = null;
        yDown = null;
    };

    useEffect(() => {
        if (!element)
            return;
        element.addEventListener('touchstart', handleTouchStart, false);
        element.addEventListener('touchmove', handleTouchMove, false);
        return () => {
            element.removeEventListener('touchstart', handleTouchStart);
            element.removeEventListener('touchmove', handleTouchMove);
        }
    }, [element, callb])

}

export default TouchMovement;