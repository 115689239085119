// extracted by mini-css-extract-plugin
export var buttonContainer = "styles-module--buttonContainer--0Cw8p";
export var container = "styles-module--container--4C5k0";
export var disabled = "styles-module--disabled--mr5Qj";
export var error = "styles-module--error--b+lL3";
export var fakeanchor = "styles-module--fakeanchor--6dq3b";
export var formContainer = "styles-module--formContainer--8H4Q6";
export var heading = "styles-module--heading--froM0";
export var loader = "styles-module--loader--zu1BL";
export var optional = "styles-module--optional--92G8i";
export var overlay = "styles-module--overlay--cKiM6";
export var paragraph = "styles-module--paragraph--DgjjY";
export var show = "styles-module--show--b9cSx";
export var spin = "styles-module--spin--r+wMT";