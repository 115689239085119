// extracted by mini-css-extract-plugin
export var bubble = "styles-module--bubble--R4aj-";
export var bubblepos = "styles-module--bubblepos--XRvW4";
export var container = "styles-module--container--bfe5p";
export var exit1 = "styles-module--exit1--PKRit";
export var exit2 = "styles-module--exit2--DSxGF";
export var exitall = "styles-module--exitall--cytSI";
export var exitbutton = "styles-module--exitbutton--Co53G";
export var exitclick = "styles-module--exitclick--PsW2x";
export var exitshowup = "styles-module--exitshowup--zuMxb";
export var gamebubble = "styles-module--gamebubble--GS-xC";
export var gamediv = "styles-module--gamediv--6o4AS";
export var gameoverlay = "styles-module--gameoverlay--wHzmR";
export var minimized = "styles-module--minimized--kX7Ei";
export var movein = "styles-module--movein--Gk53X";
export var overlay = "styles-module--overlay--B+SSK";
export var pizzaguy = "styles-module--pizzaguy--+puJS";
export var popin = "styles-module--popin--u9iNY";
export var showup = "styles-module--showup--b-PDF";