import * as React from 'react';
import * as styles from './styles.module.css';
import ButtonHover from '../../ui/ButtonHover/ButtonHover';
import { Link } from 'gatsby';

export default function CookieBanner({ cookiesAllowed, setCookiesAllowed, showBanner, setBanner }) {

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  React.useEffect(() => {
    if (document.cookie.includes('cookiebanner=allow')) {
      setCookiesAllowed(true);
      return;
    }
    if (document.cookie.includes('cookiebanner=deny')) {
      setCookiesAllowed(false);
      return;
    }
    setBanner(true);
  }, []);

  React.useEffect(() => {
    if (cookiesAllowed)
      setBanner(false);
  }, [cookiesAllowed]);

  const acceptCookies = () => {
    setCookiesAllowed(true);
    setCookie('cookiebanner', 'allow', 365);
    setBanner(false);
  };

  const declineCookies = () => {
    setCookiesAllowed(false);
    setCookie('cookiebanner', 'deny', 365);
    setBanner(false);
  };

  return (
    <>
      {
        showBanner &&
        <div className={styles.cookiebanner}>
          <div className={styles.innercontainer}>
            <div className={styles.textcontainer}>
              <p className={styles.cookietext}>Diese Seite verwendet Cookies!</p>
              <Link className={styles.moretext} to={'/datenschutz'}>Mehr erfahren</Link>
            </div>
            <div className={styles.buttoncontainer}>
              <a className={styles.buttondisallow} onClick={declineCookies}>Verweigern</a>
              <ButtonHover useGatsbyLink={false} onclick={acceptCookies}>Erlauben</ButtonHover>
            </div>
          </div>
        </div>
      }
    </>
  )
}