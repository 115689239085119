import clsx from "clsx";
import * as React from "react";
import * as styles from "./styles.module.css";
import useOnScreen from "../../../hooks/useOnScreen";
import { animated, useSpring } from "react-spring";

import ThumbsUpIcon from "../../../images/icons/thumbs-up.svg";
import RestaurantIcon from "../../../images/icons/restaurant.svg";
import ShippingIcon from "../../../images/icons/shipping.svg";

export default function LocationMapSection(props) {

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const isVisible1 = useOnScreen(ref1);
  const isVisible2 = useOnScreen(ref2);
  const isVisible3 = useOnScreen(ref3);

  const style1 = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)'
    },
    to: {
      opacity: isVisible1 ? 1 : 0,
      transform: isVisible1 ? 'translateY(0px)' : 'translateY(-25px)'
    },
    delay: props.isMobile ? 300 : 200
  });

  const style2 = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)'
    },
    to: {
      opacity: isVisible2 ? 1 : 0,
      transform: isVisible2 ? 'translateY(0px)' : 'translateY(-25px)'
    },
    delay: props.isMobile ? 300 : 400
  });

  const style3 = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)'
    },
    to: {
      opacity: isVisible3 ? 1 : 0,
      transform: isVisible3 ? 'translateY(0px)' : 'translateY(-25px)'
    },
    delay: props.isMobile ? 300 : 600
  });

  return (
    <div id='info' className={styles.container}>
      <div className={styles.section}>
        <div>
          <div className={styles.heading}>
            <h1 className={clsx(styles.headingtext, styles.underline)}>Über uns</h1>
          </div>
          <div className={styles.features}>
            <animated.div style={style1} className={styles.feature}>
              <div className={styles.icon}>
                <img src={ThumbsUpIcon} alt='Thumbs up Icon' />
              </div>
              <span className={styles.title}>Selbstgemacht</span>
              <p>
                Genießen Sie unsere selbstgemachten Speisen, zubereitet mit ausgewählten Zutaten.
              </p>
              <div ref={ref1} style={{ opacity: 0 }}></div>
            </animated.div>
            <animated.div style={style2} className={styles.feature}>
              <div className={styles.icon}>
                <img src={ShippingIcon} alt='Truck Icon' />
              </div>
              <span className={styles.title}>Lieferung</span>
              <p>Gerne liefern wir Ihre Bestellung ganz bequem zu Ihnen nach Hause.</p>
              <div ref={ref2} style={{ opacity: 0 }}></div>
            </animated.div>
            <animated.div style={style3} className={styles.feature}>
              <div className={styles.icon}>
                <img src={RestaurantIcon} alt='Restaurant Icon' />
              </div>
              <span className={styles.title}>Vor Ort</span>
              <p>
                Genießen Sie unsere Gerichte vor Ort - auch zu Feierlichkeiten. Wir bieten Platz für
                bis zu 20 Personen.
              </p>
              <div ref={ref3} style={{ opacity: 0 }}></div>
            </animated.div>
          </div>
        </div>
      </div>
    </div>
  );
}
