// extracted by mini-css-extract-plugin
export var button = "styles-module--button--3GVN0";
export var canvasoverlay = "styles-module--canvasoverlay--qmKiE";
export var formcontainer = "styles-module--formcontainer--6FmcS";
export var gamecontainer = "styles-module--gamecontainer--DYR8I";
export var loader = "styles-module--loader--s-y3Z";
export var privacytext = "styles-module--privacytext--nhFCr";
export var showup = "styles-module--showup--Mq1KI";
export var spin = "styles-module--spin--W4wC+";
export var startgametext = "styles-module--startgametext--qQXuk";
export var textbox = "styles-module--textbox--nM0KU";
export var textboxcontainer = "styles-module--textboxcontainer--usPql";
export var winninggametext = "styles-module--winninggametext--a26nv";